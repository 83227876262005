<template>
  <div
      class="china"
      :style="{ height: height, width: width }"
      ref="chart"
  ></div>
</template>
<script>
import axios from "axios"
import { provinces, cityMap } from './../../api/map'
import { debounce } from "lodash";
const echarts = require("echarts");
require("echarts/map/js/china")
export default {
  components: {},
  props: {
    mapOptions: {
      type:Array,
      default:[]
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    riskarning: {
      type: Number,
      default: 1,
    },
    regionName:{
      type:String,
      default:'全国'
    },
    cityName:{
      type:String,
      default:''
    },
    pid:{
      type: [String,Number],
      default:'1'
    },
    cid:{
      type: [String,Number],
      default:''
    },
    did:{
      type: [String,Number],
      default:''
    }
  },
  data() {
    return {
      mapType:'china',
      routeName:''
    };
  },
  computed: {
  },
  watch: {
    mapOptions: {
      handler(val, olVal) {
        //需要在这从新执行一次
        //if (val) {
        this.initCharts();
        //}
      },
      deep: true,
    },
    regionName: function (val) {
      // console.log('map-proName',val);
      let name = val;
      if(!name){
        name = '全国'
      }
      this.echartsMapClick(name)
      //}
    },
    cityName:function(val){
      // console.log('map-cityName',val);
      if(val!=''){
        this.echartsMapClick(val)
      }else{
        this.echartsMapClick(this.regionName)
      }
    },
  },
  mounted() {

    let _this = this
    this.routeName = this.$route.name

    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    // console.log(this.riskarning)
    // if(this.regionName){
    if(this.cityName){
      this.echartsMapClick(this.cityName)
    }else{
      // if(this.regionName=='北京'|| this.regionName=='天津'|| this.regionName=='上海'|| this.regionName=='重庆'|| this.regionName=='香港'|| this.regionName=='澳门'|| this.regionName=='台湾'){
      //   this.mapType = 'china'
      // }else{
      this.echartsMapClick(this.regionName)
      //}
    }
    //}

    //点击事件,根据点击某个省份计算出这个省份的数据
    //  this.chart.on('click', function (params) {
    //      console.log(params);
    //      _this.echartsMapClick(params.name)
    //  });
  },
  methods: {
    echartsMapClick(param){
      this.chart.dispose()
      if(param=="全国"){
        this.mapType = 'china';
      }
      if (param in provinces) {
        // 处理省模块
        let names = param;
        for (let key in provinces) {
          if (names == key) {
            //console.log('prov:',provinces[key])
            //this.mapType = provinces[key]
            this.showProvince(provinces[key], key);
            break;
          }
        }
      } else if (param in cityMap) {
        // 处理市模块
        let names = param;
        for (let key in cityMap) {
          if (names == key) {
            //console.log('city:',cityMap[key])
            //this.mapType = cityMap[key]
            this.showCitys(cityMap[key], key);
            break;
          }
        }
      }
    },
    showProvince(eName, param) {
      // console.log(eName, param,Vue.publicPath);
      axios.get(`static/json/province/${eName}.json`).then(res => {
        echarts.registerMap(eName, res.data);
        //alert("县");
        this.mapType = eName;
        this.initCharts();
      }).catch(err => {
        console.log('err',err)
      });
    },
    showCitys(cName, param) {
      //console.log(cName, param);
      // 显示县级地图
      axios.get(`static/json/citys/${cName}.json`).then(res => {
        echarts.registerMap(cName, res.data);
        //alert("县");
        this.mapType = cName;
        this.initCharts();
      }).catch(err => {
        console.log('err',err)
      });
    },
    initCharts() {
      // console.log("initCharts",this.mapOptions)
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      window.onresize = this.chart.resize;
      // 客人消费能力分析
      this.chart.setOption({
        tooltip: {
          // triggerOn: "click",
          padding: [15, 10],
          borderWidth:1,
          borderColor:'#7ccaff',
          textStyle:{
            fontSize:14
          },
          trigger: "item",
          formatter: function(e, t, n) {
            // console.log(e)
            let html = '';
            if(typeof(e.data) != "undefined"){
              if(_this.routeName == 'HomePage'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"家</div><br>";
                if(e.data.data){
                  html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>未备案："+e.data.data.greyNum+"家</span><span>红码："+e.data.data.redNum+"家</span></div>";
                  html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>黄码："+e.data.data.yellowNum+"家</span><span>绿码："+e.data.data.greenNum+"家</span></div>";
                  html+="<div><span style='margin-right:20px;'>不发卡："+e.data.data.blackNum+"家</span></div>";
                }
              }else if(_this.routeName == 'HomeTrade'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"</div><br>";
                if(e.data){
                  html+="<div style='margin-bottom:10px;'><span>交易笔数："+e.data.value+"笔</span></div>";
                  html+="<div style='margin-bottom:10px;'><span>交易金额："+parseFloat(e.data.money).toFixed(2)+"元</span></div>";
                  html+="<div style='margin-bottom:10px;'><span>兑付比："+e.data.ratio+"</span></div>";
                }
              }else if(_this.routeName == 'HomeAcceptance'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  </div><br>";
                if(e.data){
                  html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>已投诉："+e.data.compaintNum+"件</span><span>待审核："+e.data.pendingNum+"件</span></div>";
                  html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>待分配："+e.data.distributionNum+"件</span><span>处理中："+e.data.processingNum+"件</span></div>";
                  html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>已处理："+e.data.dealNum+"件</span><span>已撤销："+e.data.cancelNum+"件</span></div>";
                }
              }else if(_this.routeName == 'HomePolice'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"家</div><br>";
                if(e.data.data){
                  if(_this.riskarning === 1){
                    html+="<div style='margin-bottom:10px;'><span>高风险："+e.data.data.high+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span>中风险："+e.data.data.mid+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span>低风险："+e.data.data.low+"家</span></div>";
                  }else if(_this.riskarning === 3){
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>一级预警："+e.data.data.earlyWarning1+"家</span><span>二级预警："+e.data.data.earlyWarning2+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>三级预警："+e.data.data.earlyWarning3+"家</span><span>四级预警："+e.data.data.earlyWarning4+"家</span></div>";
                    html+="<div><span style='margin-right:20px;'>五级预警："+e.data.data.earlyWarning5+"家</span></div>";
                  }else if(_this.riskarning === 2){
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>AAA："+e.data.data.credit3A+"家</span><span>AA："+e.data.data.credit2A+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>A："+e.data.data.credit1A+"家</span><span>BBB："+e.data.data.credit3B+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>BB："+e.data.data.credit2B+"家</span><span>B："+e.data.data.credit1B+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>CCC："+e.data.data.credit3C+"家</span><span>CC："+e.data.data.credit2C+"家</span></div>";
                    html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>C："+e.data.data.credit1C+"家</span><span>D："+e.data.data.creditD+"家</span></div>";
                  }
                }
              }else if(_this.routeName == 'HomeConsumptionstructure'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"元</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>儿童："+e.data.childAmountNum+"元</span><span>少年："+e.data.juvenileAmountNum+"元</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>青年："+e.data.youthAmountNum+"元</span><span>中年："+e.data.middleAmountNum+"元</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>老年："+e.data.oldAmountNum+"元</span><span>其他："+e.data.unkownAgeAmountNum+"元</span></div>";
              }else if(_this.routeName == 'HomeEpidemicpreventioncontrol'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"家</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>正常营业门店数："+e.data.normalShopNum+"家</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>异常营业门店数："+e.data.abnormalShopNum+"家</span></div>";
              }else if(_this.routeName == 'HomePrepaidcontract'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"份</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>已签约："+e.data.signNum+"份</span><span style='margin-right:20px;'>未签约："+e.data.unSignNum+"份</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>已履约："+e.data.keepedContractNum+"份</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>履约中："+e.data.keepContractNum+"份</span><span style='margin-right:20px;'>已违约："+e.data.unKeepContractNum+"份</span></div>";
                // html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>履约："+e.data.keepContractNum+"份</span></div>";
                // html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>违约："+e.data.unKeepContractNum+"份</span></div>";
              }else if(_this.routeName == 'HomePlace'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>警告:"+e.data.warning+"次</span><span style='margin-right:20px;'>罚款:"+e.data.fine+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>没收违法所得:"+e.data.illegalincome+"次</span><span style='margin-right:20px;'>责令停产停业:"+e.data.production+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>吊销/暂扣执照:"+e.data.license+"次</span><span style='margin-right:20px;'>行政拘留:"+e.data.detention+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>其他:"+e.data.other+"次</span></div>";

              }else if(_this.routeName == 'HomeSynthesizeSupervise'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>应急处理:"+e.data.data.warning+"次</span><span style='margin-right:20px;'>纠纷处理:"+e.data.data.fine+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>投诉处理:"+e.data.data.illegalincome+"次</span><span style='margin-right:20px;'>责令整改:"+e.data.data.production+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>执法检查:"+e.data.data.license+"次</span><span style='margin-right:20px;'>依法处置:"+e.data.data.detention+"次</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>其他:"+e.data.data.other+"次</span></div>";

              }else if(_this.routeName == 'HomeOther'){
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name +"  " + e.data.value + "家</div><br>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>登记:"+e.data.value1+"家</span><span style='margin-right:20px;'>注销:"+e.data.value2+"家</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>吊销:"+e.data.value3+"家</span><span style='margin-right:20px;'>歇业:"+e.data.value4+"家</span></div>";
                html+="<div style='margin-bottom:10px;'><span style='margin-right:20px;'>停业:"+e.data.value5+"家</span></div>";

              }else{
                html = "<div style='font-size:16px;font-weight:bold;'>"+e.data.name+"  "+e.data.value+"</div><br>";
              }
            }
            return html;
          }
        },
        visualMap: {
          min: 0,
          max: 10000,
          left: 26,
          bottom: 40,
          showLabel: !0,
          text: ["", ""],
          pieces: [
            {
              gt: 10000,
              label: "≥100000",
              color: "#2452ad",
            },
            {
              gt: 1000,
              lte: 9999,
              label: "1000-9999",
              color: "#3f78c7",
            },
            {
              gte: 100,
              lte: 999,
              label: "100-999",
              color: "#518fd6",
            },
            {
              gte: 10,
              lt: 99,
              label: "10-99",
              color: "#75b9f6",
            },
            {
              gt: 0,
              lt: 10,
              label: "1-9",
              color: "#7ccaff",
            },
            {
              value: 0,
              color: "#7ccaff",
            },
          ],
          textStyle: {
            color: "#419cbb",
          },
        },
        geo: {
          map: _this.mapType,
          top: "11%",
          left: "21%",
          aspectScale: 0.75,
          silent:true,
          label: {
            emphasis: {
              show: false,
            },
          },
          regions: [
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                normal: {
                  opacity: 0,
                  label: {
                    show: false,
                  },
                },
              },
            },
          ],
          itemStyle: {
            normal: {
              borderColor: "rgba(147, 235, 248, 0)",
              borderWidth: 0.5,
              areaColor: {
                x: 1000,
                y: 1000,
                x2: 1000,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#69c5d8", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#126caf", // 50% 处的颜色
                  },
                ],
                global: true, // 缺省为 false
              },
              opacity: 1,
            },
            emphasis: {
              show: false,
              areaColor: "#69c5d8",
            },
          },
          z: 2,
        },
        series: [
          {
            type: "map",
            mapType: _this.mapType,
            label: {
              show: true,
              color: "#000",
              emphasis: {
                show: false,
                color: "#fff",
              },
            },
            top: "9%",
            left: "21%",
            aspectScale: 0.75,
            roam: false,
            data: _this.mapOptions,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>

<style lang="scss" scoped>
.china {
  /*width: 750px;*/
  /*height: 490px;*/
  // background: url("../../../assets/img/home/mapbg.png") no-repeat;
  background-size: 100% 100%;
}
</style>
