<template>
  <div ref="container" :style="{ opacity: 0.9, height: height, width: width }" />
</template>

<script>
import "./index.css";
import custom from "./custom.json";
import icon from "@/assets/imgs/maker.png";
let BMap = window.BMap;
export default {
  name: "baidumap",
  components: {},
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    riskarning: {
      type: Number,
      default: 1,
    },
    regionName: {
      type: String,
      default: "全国",
    },
    // jsondatatemplate:{
    //   type: Function,
    // },
    areaName: {
      type: String,
      default: "全国",
    },
    cityName: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: [],
    },
    pid: {
      type: [String, Number],
      default: "1",
    },
    cid: {
      type: [String, Number],
      default: "",
    },
    did: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      infoBoxArr: [],
      markerArr: []
    };
  },
  watch: {
    data: {
      handler(val) {

        if (val) {
          this.add();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.add();
  },
  updated() {
    this.add();
  },

  methods: {
    showTip(infoBox, marker) {
      infoBox.open(marker);
    },

    hiddenTip(infoBox, marker) {
      infoBox.close(marker);
    },
    clearTip() {
      this.infoBoxArr.forEach((s) => {
        s[0].close(s[1]);
      });
    },
    add() {
      // riskarning
      let data = this.data;
      let name = this.cityName + this.areaName;
      let islj = false;

      // eslint-disable-next-line no-empty
      if (name == '两江新区') {
        name = '重庆市';
        islj = true;
      } else {
        islj = false;
      }

      let me = this;
      if (data.length != 0) {
        const container = this.$refs.container;
        me.bmap = new BMap.Map(container, {
          enableAutoResize: true,
          enableMapClick: false,
        });
        me.bmap.enableScrollWheelZoom();
        container.style.backgroundColor = "#000";
        this.point = [116.331398, 39.897445];
        const point = new BMap.Point(this.point[0], this.point[1]);

        this.centerPoint = point;


        me.bmap.centerAndZoom(point, 10);

        me.bmap.setMapStyleV2({
          styleId: "e1a6fbddbcd0afdd4fbd8277faceb825",
          // styleJson: custom,
        });

        var bdary = new BMap.Boundary();


        bdary.get(name, function (rs) {
          //获取行政区域
          var count = rs.boundaries.length; //行政区域的点有多少个
          if (count === 0) {
            console.log("未能获取当前输入行政区域");
            return;
          }
          var pointArray = [];
          for (var i = 0; i < count; i++) {
            var ply = new BMap.Polygon(rs.boundaries[i], {
              strokeWeight: 2,
              strokeColor: "#0DAEF3",
              fillColor: "rgba(0,245,254,0.3)",
            }); //建立多边形覆盖物
            if (me.bmap !== null) {
              me.bmap.addOverlay(ply); //添加覆盖物
            }
            pointArray = pointArray.concat(ply.getPath());
          }
          if (!islj) {
            setTimeout(() => {
              if (me.bmap !== null) me.bmap.setViewport(pointArray); //调整视野
            }, 1000);
          }


        });






        // this.markerCluster && this.markerCluster.clearMarkers();
        var myGeo = new BMap.Geocoder();
        data.map((v, i) => {
          myGeo.getPoint(
            v.name,
            function (point) {
              if (me.bmap !== null) {
                if (islj) {
                  me.bmap.centerAndZoom(point, 13);
                }
                else if (me.bmap !== null) {
                  me.bmap.centerAndZoom(point, 10);
                }
              }
              var myIcon = new BMap.Icon(icon, new BMap.Size(52, 26));
              me.marker = new BMap.Marker(point, {
                icon: myIcon,
                title: v.name,
              });
              if (me.bmap !== null) {
                me.bmap.addOverlay(me.marker);
              }
              let html = "";

              if (i == data.length - 1) {
                //console.log(me.markerArr, "me.markerArrme.markerArr");
                me.markerArr.forEach((s) => me.bmap.addOverlay(s));

                me.markerCluster = new window.BMapLib.MarkerClusterer(
                  me.bmap,
                  {
                    markers: me.markerArr,
                    minClusterSize: 2,
                  }
                );
              }

              // new Promise(resolve=>{
              //     resolve(me.markerArr.push(me.marker));
              // }).then(res=>{
              //    console.log(res)
              // })

              if (me.bmap !== null) {
                me.bmap.addOverlay(me.marker);
              }

              if (me.$route.name == "HomePage") {
                html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}${v.value}家</div>
                       <div class="map-namechild">未备案：  <span class="map-namechildvalue">${v.data.greyNum}家</span></div>
                       <div class="map-namechild">红码：  <span class="map-namechildvalue">${v.data.redNum}家</span></div>
                       <div class="map-namechild">黄码：  <span class="map-namechildvalue">${v.data.yellowNum}家</span></div>
                       <div class="map-namechild">绿码：  <span class="map-namechildvalue">${v.data.greenNum}家</span></div>
                       <div class="map-namechild">不发卡：  <span class="map-namechildvalue">${v.data.blackNum}家</span></div>
                    </div>`;
              } else if (me.$route.name == "HomeTrade") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}</div>
                         <div class="map-namechild">交易笔数:  <span class="map-namechildvalue">${v.value
                  }笔</span></div>
                         <div class="map-namechild">交易金额:  <span class="map-namechildvalue">${parseFloat(
                    v.money
                  ).toFixed(2)}元</span></div>
                         <div class="map-namechild">兑付比:    <span class="map-namechildvalue">${v.ratio
                  }</span></div>
                      </div>`;
              } else if (me.$route.name == "HomeAcceptance") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}</div>
                         <div class="map-namechild">已投诉：  <span class="map-namechildvalue">${v.compaintNum}件</span></div>
                         <div class="map-namechild">待审核：  <span class="map-namechildvalue">${v.pendingNum}次</span></div>
                         <div class="map-namechild">待分配：  <span class="map-namechildvalue">${v.distributionNum}次</span></div>
                         <div class="map-namechild">处理中：  <span class="map-namechildvalue">${v.processingNum}次</span></div>
                         <div class="map-namechild">已处理：  <span class="map-namechildvalue">${v.dealNum}次</span></div>
                         <div class="map-namechild">已撤销：  <span class="map-namechildvalue">${v.cancelNum}次</span></div>
                      </div>`;
              } else if (me.$route.name == "HomePolice") {
                if (me.riskarning === 1) {
                  html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}${v.value}家</div>
                       <div class="map-namechild">高风险：  <span class="map-namechildvalue">${v.data.high}家</span></div>
                       <div class="map-namechild">中风险：  <span class="map-namechildvalue">${v.data.mid}家</span></div>
                       <div class="map-namechild">低风险：  <span class="map-namechildvalue">${v.data.low}家</span></div>
                    </div>`;
                } else if (me.riskarning === 2) {
                  html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}${v.value}家</div>
                       <div class="map-namechild">AAA：  <span class="map-namechildvalue">${v.data.credit3A}家</span></div>
                       <div class="map-namechild">AA：  <span class="map-namechildvalue">${v.data.credit2A}家</span></div>
                       <div class="map-namechild">A：  <span class="map-namechildvalue">${v.data.credit1A}家</span></div>
                       <div class="map-namechild">BBB：  <span class="map-namechildvalue">${v.data.credit3B}家</span></div>
                       <div class="map-namechild">BB：  <span class="map-namechildvalue">${v.data.credit2B}家</span></div>
                       <div class="map-namechild">B：  <span class="map-namechildvalue">${v.data.credit1B}家</span></div>
                       <div class="map-namechild">CCC：  <span class="map-namechildvalue">${v.data.credit3C}家</span></div>
                       <div class="map-namechild">CC：  <span class="map-namechildvalue">${v.data.credit2C}家</span></div>
                       <div class="map-namechild">C：  <span class="map-namechildvalue">${v.data.credit1C}家</span></div>
                       <div class="map-namechild">D：  <span class="map-namechildvalue">${v.data.creditD}家</span></div>
                    </div>`;

                } else if (me.riskarning === 3) {
                  html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}${v.value}家</div>
                       <div class="map-namechild">一级预警：  <span class="map-namechildvalue">${v.data.earlyWarning1}家</span></div>
                       <div class="map-namechild">二级预警：  <span class="map-namechildvalue">${v.data.earlyWarning2}家</span></div>
                       <div class="map-namechild">三级预警：  <span class="map-namechildvalue">${v.data.earlyWarning3}家</span></div>
                       <div class="map-namechild">四级预警：  <span class="map-namechildvalue">${v.data.earlyWarning4}家</span></div>
                       <div class="map-namechild">五级预警：  <span class="map-namechildvalue">${v.data.earlyWarning5}家</span></div>
                    </div>`;
                }
              } else if (me.$route.name == "HomePrepaidcontract") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}  ${v.value}份</div>
                         <div class="map-namechild">已签约:   <span class="map-namechildvalue">${v.signNum}份</span></div>
                         <div class="map-namechild">未签约:   <span class="map-namechildvalue">${v.unSignNum}份</span></div>
                         <div class="map-namechild">履约中:     <span class="map-namechildvalue">${v.keepContractNum}份</span></div>
                         <div class="map-namechild">已履约:     <span class="map-namechildvalue">${v.keepedContractNum}份</span></div>
                         <div class="map-namechild">已违约:     <span class="map-namechildvalue">${v.unKeepContractNum}份</span></div>
                      </div>`;
              } else if (me.$route.name == "HomeConsumptionstructure") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}${v.value}元</div>
                         <div class="map-namechild">儿童：  <span class="map-namechildvalue">${v.childAmountNum}元</span></div>
                         <div class="map-namechild">少年：  <span class="map-namechildvalue">${v.juvenileAmountNum}元</span></div>
                         <div class="map-namechild">青年：  <span class="map-namechildvalue">${v.youthAmountNum}元</span></div>
                         <div class="map-namechild">中年：  <span class="map-namechildvalue">${v.middleAmountNum}元</span></div>
                         <div class="map-namechild">老年：  <span class="map-namechildvalue">${v.oldAmountNum}元</span></div>
                         <div class="map-namechild">其他：  <span class="map-namechildvalue">${v.unkownAgeAmountNum}元</span></div>
                      </div>`;
              } else if (me.$route.name == "HomeEpidemicpreventioncontrol") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}${v.value}家</div>
                         <div class="map-namechild">正常营业门店数：  <span class="map-namechildvalue">${v.normalShopNum}家</span></div>
                         <div class="map-namechild">异常营业门店数：  <span class="map-namechildvalue">${v.abnormalShopNum}家</span></div>
                      </div>`;
              } else if (me.$route.name == "HomePlace") {
                html = `<div class="baidu-tooltip-wrap">
                         <div class="map-name">${v.name}</div>
                         <div class="map-namechild">警告:  <span class="map-namechildvalue">${v.warning}次</span></div>
                         <div class="map-namechild">罚款:  <span class="map-namechildvalue">${v.fine}次</span></div>
                          <div class="map-namechild">没收违法所得:  <span class="map-namechildvalue">${v.illegalincome}次</span></div>
                         <div class="map-namechild">责令停产停业:  <span class="map-namechildvalue">${v.production}次</span></div>
                          <div class="map-namechild">吊销/暂扣执照:  <span class="map-namechildvalue">${v.license}次</span></div>
                         <div class="map-namechild">行政拘留:  <span class="map-namechildvalue">${v.detention}次</span></div>
                          <div class="map-namechild">其他:  <span class="map-namechildvalue">${v.other}次</span></div>
                      </div>`;
              } else if (me.$route.name == "HomeSynthesizeSupervise") {
                html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}  ${v.value}次</div>
                        <div class="map-namechild">应急处理:   <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">执法检查:   <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">投诉处理:     <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">依法处置:     <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">责令整改:     <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">纠纷处理:     <span class="map-namechildvalue">${v.data.warning}份</span></div>
                         <div class="map-namechild">其他:     <span class="map-namechildvalue">${v.data.warning}份</span></div>
                    </div>`;
              } else if (me.$route.name == "HomeOther") {
                html = `<div class="baidu-tooltip-wrap">
                       <div class="map-name">${v.name}  ${v.value}家</div>
                         <div class="map-namechild">登记:   <span class="map-namechildvalue">${v.data.value1}家</span></div>
                         <div class="map-namechild">注销:   <span class="map-namechildvalue">${v.data.value2}家</span></div>
                         <div class="map-namechild">吊销:   <span class="map-namechildvalue">${v.data.value3}家</span></div>
                         <div class="map-namechild">歇业:   <span class="map-namechildvalue">${v.data.value4}家</span></div>
                         <div class="map-namechild">停业:   <span class="map-namechildvalue">${v.data.value5}家</span></div>
                    </div>`;
              }

              let infoBox = new window.BMapLib.InfoBox(me.bmap, html, {
                enableAutoPan: true,
                align: window.INFOBOX_AT_TOP,
              });

              me.infoBoxArr.push([infoBox, me.marker]);
              me.marker.addEventListener("mouseover", function () {
                me.showTip(infoBox, this);
              });

              me.marker.addEventListener("mouseout", function () {
                // me.hiddenTip(infoBox, this);
                me.clearTip();
              });
            },
            name
          );
        });
        // }, 0);
      } else {
        const container = this.$refs.container;
        me.bmap = new BMap.Map(container, {
          enableAutoResize: true,
          enableMapClick: false,
        });
        container.style.backgroundColor = "#000";

        this.point = [116.331398, 39.897445];
        const point = new BMap.Point(this.point[0], this.point[1]);
        this.centerPoint = point;
        if (islj) {
          me.bmap.centerAndZoom(point, 13);
        }
        else if (me.bmap !== null) {
          me.bmap.centerAndZoom(point, 10);
        }

        me.bmap.setMapStyleV2({
          styleId: "e1a6fbddbcd0afdd4fbd8277faceb825",
        });
      }
    },
  },
  destroyed() {
    this.bmap = null;
    // this.data = [];
  },
};
</script>
